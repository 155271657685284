import dayjs from 'dayjs';

const getMinutes = (value = '00:00') => dayjs(value, 'h:mm').minute();

const suffixPrefix = (prefix: string) => (prefix ? `${prefix}.` : null);

export const getMaxValueValidator =
  (maxValue: number, translationPrefix = '') =>
  (value: number): string | undefined => {
    const prefix = suffixPrefix(translationPrefix);

    return value > maxValue ? `${prefix}maxValue` : undefined;
  };

export const getMinValueValidator =
  (minValue: number, translationPrefix = '') =>
  (value: number): string | undefined => {
    const prefix = suffixPrefix(translationPrefix);

    return value < minValue ? `${prefix}minValue` : undefined;
  };

const timeIsBefore = (time: string, refTime: string): boolean | undefined => {
  if (
    !refTime ||
    !time ||
    !/^([01]?[0-9]|2[0-3]):[0-5][0-9]/.test(time) ||
    !/^([01]?[0-9]|2[0-3]):[0-5][0-9]/.test(refTime)
  ) {
    return undefined;
  }

  return getMinutes(time) < getMinutes(refTime);
};

export const getMinTimeValueValidator =
  (minValue: string, translationPrefix = '', key = '') =>
  (value: string): string | undefined => {
    const isBefore = timeIsBefore(value, minValue);
    const prefix = suffixPrefix(translationPrefix);

    return isBefore ? `${prefix}${key || 'minValue'}` : undefined;
  };

export const isRequired = (value: string): string | undefined => {
  if (undefined === value || !value) {
    return 'required';
  }

  return undefined;
};

export const isRequiredAndNotNewItem = (value: string): string | undefined => {
  if (value !== 'new_item') {
    return isRequired(value);
  }

  return 'required';
};

export const isValidEmail = (value: string): string | undefined => {
  if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'auth.email.invalid';
  }

  return undefined;
};

export const isValidPhone = (value: string): string | undefined =>
  !value || /^(\+33|0)[0-9]{9}$/.test(value.replace(/ /g, ''))
    ? undefined
    : 'account.phone.invalid';

export const isValidTime = (value: string): string | undefined =>
  dayjs(value, 'HH:mm').isValid() ? undefined : 'time.invalid';
