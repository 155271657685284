import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import {
  colors,
  font,
  radius,
  spacing,
  StatusProp,
} from '../../../styles/theme';

const getMessageStatusStyles = (props: StatusProp) => {
  switch (props.status) {
    case 'error':
      return css`
        color: ${colors.danger.base};
        background: ${rgba(colors.danger.base, 0.125)};
        a:hover {
          color: ${colors.danger.dark};
        }
      `;
    case 'warning':
      return css`
        color: ${colors.warning.dark};
        background: ${rgba(colors.warning.base, 0.125)};
        a:hover {
          color: ${colors.warning.darker};
        }
      `;
    case 'success':
      return css`
        color: ${colors.success.dark};
        background: ${rgba(colors.success.base, 0.125)};
        a:hover {
          color: ${colors.success.darker};
        }
      `;
    default:
      return css`
        color: ${colors.neutral.base};
        background: ${rgba(colors.neutral.base, 0.125)};
        a:hover {
          color: ${colors.neutral.dark};
        }
      `;
  }
};

const Message = styled.div<StatusProp>`
  padding: ${spacing.xs} ${spacing.s};
  font-size: ${font.size.s[0]};
  border-radius: ${radius.xs};
  a {
    color: inherit;
  }
  ${props => getMessageStatusStyles(props)};
`;

export default Message;
