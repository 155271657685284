import { DataField, Form, SubmissionError } from 'dataformjs';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AuthBox from '../components/base/AuthBox';
import FullPageCenter from '../components/base/FullPageCenter';
import Message from '../components/base/Message';
import Title from '../components/base/Title';
import App from '../components/layout/App';
import User from '../models/User';
import { font } from '../styles/theme';
import { isRequired, isValidEmail } from '../utils/validators';

const LostPassword: FC = () => {
  const { t } = useTranslation();
  const [pending, setPending] = useState<boolean>(false);
  const [successed, setSuccessed] = useState<boolean>(false);

  const handleOnSubmit = async (values: { email: string }) => {
    setPending(true);
    const { email } = values;
    if (!email) {
      setPending(false);
      throw new SubmissionError({ _error: 'account.password.lost.no-email' });
    }

    try {
      await User.sendPasswordResetEmail(email);
    } catch (e) {
      console.error(e);
      setPending(false);
      throw new SubmissionError({ _error: 'account.password.lost.failed' });
    }

    setSuccessed(true);
    setPending(false);
  };

  return (
    <App naked>
      <FullPageCenter>
        <AuthBox standard>
          <div>
            <Title className="mb-6" size={font.size.xl[0]}>
              {t('account.password.lost.title')}
            </Title>
            <Form
              footerProps={{
                className: 'mt-6 flex justify-end',
              }}
              isSubmissive={!pending && !successed}
              name="password"
              onSubmit={handleOnSubmit}
              submitProps={{
                label: 'account.password.lost.submit',
              }}
            >
              <DataField
                componentType="input"
                label="account.email.label"
                name="email"
                required
                type="email"
                validate={[isRequired, isValidEmail]}
              />
            </Form>
            <p className="mt-12 text-justify">
              {t('account.password.lost.lostPassword')}
            </p>

            {successed && (
              <Message className="mt-6" status="success">
                {t('account.password.lost.success')}
              </Message>
            )}
          </div>
        </AuthBox>
      </FullPageCenter>
    </App>
  );
};

export default LostPassword;
